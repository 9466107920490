import React from 'react'
import PropTypes from 'prop-types'
import ReactSVG from 'react-svg'
import Helmet from 'react-helmet'
import ReactHtmlParser from 'react-html-parser'
import ReactMarkdown from 'react-markdown'
import Img from 'gatsby-image'

import Testimonials from '@/components/Testimonials/Slider'
import Layout from '@/components/Layout'
import Features from '@/components/Features/List'
import FeatureBox from '@/components/Features/Box'

import SEO from '@/helpers/SEOHelper.js'

export const HomePageTemplate = ({ data, features, testimonials }) => {
  const promotedFeatures = data.promotedFeatures.map((item) => {
    let feature = features.find((el) => el.node.frontmatter.title === item)
    return feature
  })

  console.log(testimonials)
  return (
    <>
      <section className='flex items-center justify-center hero pt-20 pb-0 md:py-20 relative overflow-hidden'>
        <ReactSVG src='/img/bg-shape.svg' className='hero__bg'></ReactSVG>
        <div className='container text-center mx-auto py-20 relative'>
          <h1 className='text-h2 md:text-h1 max-w-4xl text-center mx-auto mb-10'>
            {ReactHtmlParser(data.hero.heading)}
          </h1>
          {data.hero.description && (
            <p className='max-w-sm mx-auto mb-10 text-gray'>
              {data.hero.description}
            </p>
          )}
          <a
            href='https://app.selectrnetball.com.au/signup'
            className='btn btn-primary mx-auto'>
            Sign up
          </a>

          <div className='container-sm mt-20'>
            <div className='hero__images'>
              <Img
                fluid={data.hero.image.childImageSharp.fluid}
                className='hero__image hero__image--1 hidden md:block'
              />
              <Img
                fluid={data.hero.image2.childImageSharp.fluid}
                className='hero__image hero__image--2'
              />
            </div>
          </div>
        </div>
      </section>

      <section className='py-20'>
        <div className='container'>
          <div className='features-list'>
            {promotedFeatures.map((item) => {
              let feature = item.node.frontmatter
              return (
                <FeatureBox
                  key={item.node.id}
                  icon={feature.icon}
                  title={feature.title}
                  content={feature.description}></FeatureBox>
              )
            })}
          </div>
        </div>
      </section>

      <section className='pt-20'>
        <div className='container'>
          <div className='flex flex-wrap md:items-center'>
            <div className='w-full md:w-1/2'>
              <h2 className='text-h2 md:text-h1 leading-none font-bold mb-8'>
                {ReactHtmlParser(data.explain.title)}
              </h2>
              <ReactMarkdown
                source={data.explain.content}
                className='text-gray mb-8 max-w-480'
              />
              <a
                href='https://app.selectrnetball.com.au/signup'
                className='btn btn-primary'>
                Sign up
              </a>
            </div>
            <div className='w-full md:w-1/2'>
              <div className='section-image'>
                <Img
                  fluid={data.explain.image.childImageSharp.fluid}
                  className='section-image__image'
                />
                <div className='section-image__decoration bg-accent'></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='bg-gray-lightest py-24'>
        <div className='container'>
          <h2 className='text-h2 md:text-h1 font-bold text-center mb-10'>
            {data.featuresTitle}
          </h2>
          <Features features={features}></Features>
        </div>
      </section>

      {testimonials.length > 2 && (
        <section className='bg-gray-lightest py-24'>
          <div className='container'>
            <h2 className='text-h2 md:text-h1 font-bold text-center mb-10'>
              {data.testimonialsTitle}
            </h2>
            <Testimonials testimonials={testimonials} />
          </div>
        </section>
      )}

      {/* <section className='py-24'>
        <div className='container'>
          <h2 className='text-h2 md:text-h1 font-bold text-center mb-10'>{data.video.title}</h2>
          <Video
            videoSrcURL={data.video.file.publicURL}
            videoTitle={data.video.videoTitle}
            poster='https://images.unsplash.com/photo-1593642634627-6fdaf35209f4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2100&q=80'
          />
        </div>
      </section> */}

      <section className='py-20'>
        <div className='container text-center'>
          <h2 className='text-h3 md:text-h2 mb-5'>Ready to get started?</h2>
          <p className='text-gray'>
            Sign up today, or reach out to us if you have any questions!
          </p>
          <div className='flex space-x-5 justify-center items-center mt-5'>
            <a
              href='https://app.selectrnetball.com.au/signup'
              className='btn btn-primary'>
              Sign up
            </a>
            <a href='/contact' className='btn btn-outlined'>
              Contact us
            </a>
          </div>
        </div>
      </section>
    </>
  )
}

const HomePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const { edges: features } = data.features
  const testimonials = data.testimonials.edges.map((item) => {
    return { ...item.node.frontmatter, id: item.node.id }
  })

  return (
    <Layout>
      <Helmet>
        <meta
          name='description'
          content={SEO.description(frontmatter.seo.description)}
        />
        <meta property='og:title' content={SEO.title(frontmatter.seo.title)} />
        <meta
          property='og:description'
          content={SEO.description(frontmatter.seo.description)}
        />
        <meta property='og:image' content={SEO.image('null')} />
        <title>{SEO.title(frontmatter.seo.title)}</title>
      </Helmet>
      <HomePageTemplate
        data={frontmatter}
        features={features}
        testimonials={testimonials}
      />
    </Layout>
  )
}

HomePage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default HomePage

export const pageQuery = graphql`
  query HomePageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index" } }) {
      frontmatter {
        title
        hero {
          heading
          description
          image {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 50) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          image2 {
            childImageSharp {
              fluid(maxWidth: 270, quality: 50) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        promotedFeatures
        explain {
          title
          content
          image {
            childImageSharp {
              fluid(maxWidth: 420, quality: 50) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        featuresTitle
        testimonialsTitle
        video {
          title
          videoTitle
          file {
            id
            name
            publicURL
          }
        }
        seo {
          title
          description
        }
      }
    }
    features: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "feature" } } }
      sort: { fields: [frontmatter___order], order: ASC }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            order
            icon
            category
            description
          }
        }
      }
    }
    testimonials: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "testimonial" } } }
      sort: { fields: [frontmatter___order], order: ASC }
    ) {
      edges {
        node {
          id
          frontmatter {
            image {
              childImageSharp {
                fluid(maxWidth: 52, quality: 70) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            title
            role
            company
            testimonial
          }
        }
      }
    }
  }
`
