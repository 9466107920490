import React, { Component } from 'react'
import FeatureBox from '@/components/Features/Box'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

export default class FeaturesList extends Component {
  render() {
    const categories = Array.from(
      new Set(
        this.props.features.map((item) => item.node.frontmatter.category),
      ),
    ).filter((cat) => cat !== 'Promoted')
    const groupedFeatures = categories.map((cat) => {
      return this.props.features.filter(
        (feature) => feature.node.frontmatter.category === cat,
      )
    })
    return (
      <Tabs className='features'>
        <TabList className='features__navigation'>
          {categories.map((cat) => (
            <Tab
              key={cat}
              className='features__tab'
              selectedClassName='features__tab--active'>
              {cat}
            </Tab>
          ))}
        </TabList>
        {groupedFeatures.map((featuresList, i) => (
          <TabPanel key={i}>
            <div className='features-list mt-10'>
              {featuresList.map((item) => (
                <FeatureBox
                  key={item.node.id}
                  icon={item.node.frontmatter.icon}
                  title={item.node.frontmatter.title}
                  content={item.node.frontmatter.description}></FeatureBox>
              ))}
            </div>
          </TabPanel>
        ))}
      </Tabs>
    )
  }
}
