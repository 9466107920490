import React, { Component } from 'react'
import ReactMarkdown from 'react-markdown'
import Img from 'gatsby-image'

export default class TestimonialCard extends Component {
  render() {
    const person = this.props.item
    return (
      <article className='p-3'>
        <ReactMarkdown source={person.testimonial}></ReactMarkdown>
        <div className='flex items-center pt-1'>
          {person.image?.childImageSharp && (
            <Img
              fluid={person.image.childImageSharp.fluid}
              className='w-12 h-12 rounded-full mr-2'
              alt={person.title}
            />
          )}
          <div>
            <h2 className='text-lg font-semibold mb-1'>{person.title}</h2>
            <div className='text-gray text-sm'>{person.company}</div>
          </div>
        </div>
      </article>
    )
  }
}
