import React, { Component } from 'react'
import ReactSVG from 'react-svg'
import ReactHtmlParser from 'react-html-parser'

export default class FeatureBox extends Component {
  render() {
    return (
      <article className='feature-box'>
        <div className='feature-box__icon'>
          <ReactSVG src={`/img/${this.props.icon}.svg`}></ReactSVG>
        </div>
        <h2 className='feature-box__title'>{this.props.title}</h2>
        <div className='feature-box__description'>
          {ReactHtmlParser(this.props.content)}
        </div>
      </article>
    )
  }
}
